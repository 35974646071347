import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { CaretLeft, CaretRight, CloudUpload, Edit, PatientChart, Print, Tick } from "@remhealth/icons";

import { Alert, AnchorButton, Button, ButtonGroup, Classes, IconButton, Menu, MenuItem, Popover, useUpdateEffect } from "@remhealth/ui";
import {
  CompositionStatus,
  Encounter,
  EncounterDocumentationType,
  Instant,
  Note,
  ParticipantRole,
  PatientEncounter,
  PatientNote,
  ResourceSyncStatus,
  Signature,
  isGroupNote,
  isPatientEncounter,
  isPatientNote,
  securityRoleIds
} from "@remhealth/apollo";
import { useApollo, usePracticePreferences, useProductFlag, useTracking, useUserSession } from "@remhealth/host";
import {
  NoteFilterOptions,
  getNoteSyncStatus,
  useAudit,
  useErrorHandler,
  useLabeling,
  useStore,
  useStoreItem
} from "@remhealth/core";
import { Text } from "~/text";
import { useAllowPatientSignatures, useNoteDialog, usePatientAccessor } from "~/contexts";
import { clinicalRoutes } from "~/routes";
import { NoteSummaryPrint, useNoteSummary } from "~/notes/summary";
import { useFinalizeNote } from "~/notes/hooks/useFinalizeNote";
import { NoteContent, NoteContentData } from "./noteContent";
import { GroupNoteViewer } from "./groupNoteViewer";
import { DeleteNoteAlert } from "./deleteNoteAlert";
import { DialogBody, Footer, FooterActions, Modal, PatientViewSwitch } from "./readOnlyNoteDialog.styles";

export interface ReadOnlyNoteDialogProps {
  isOpen: boolean;
  note: Note;
  allowEditSwitch?: boolean;
  allowOpenChartSwitch?: boolean;
  allowDeleteNote?: boolean;
  showPagination?: DialogPaginationProps;
  syncIssuesView?: boolean;
  onClose?: () => void;
}

export const ReadOnlyNoteDialog = (props: ReadOnlyNoteDialogProps) => {
  const { note } = props;

  if (!isPatientNote(note)) {
    const groupNotesFilters: NoteFilterOptions = {
      partOfIds: [note.id],
    };

    return (
      <GroupNoteViewer {...props} activeNoteIndex={0} filters={groupNotesFilters} />
    );
  }

  return (
    <PatientNotePreview {...props} note={note} />
  );
};

interface PatientNotePreviewProps extends Omit<ReadOnlyNoteDialogProps, "note"> {
  note: PatientNote;
}

function PatientNotePreview(props: PatientNotePreviewProps) {
  const { isOpen, onClose, showPagination, syncIssuesView, allowEditSwitch = false, allowOpenChartSwitch = true, allowDeleteNote = true } = props;

  const store = useStore();
  const apollo = useApollo();
  const audit = useAudit();
  const note = useStoreItem(store.notes, props.note);
  const tracking = useTracking();
  const user = useUserSession();
  const handleError = useErrorHandler();
  const noteDialog = useNoteDialog();
  const accessor = usePatientAccessor();
  const labels = useLabeling();
  const { summarizeNote } = useNoteSummary();
  const navigate = useNavigate();
  const practicePreference = usePracticePreferences();

  const enableUnsignWithinThreshold = useProductFlag("EnableUnsignWithinThreshold");
  const adminNoteDelete = useProductFlag("AdminNoteDelete");
  const isAdmin = user.permissions.hasFlag("ClinicalFlags", "PatientPopulationAll");
  const showPatientSignatures = useAllowPatientSignatures();

  const session = useUserSession();
  const currentUser = session.person;
  const { deleteNote } = useFinalizeNote();

  const [data, setData] = useState<NoteContentData>();
  const [loading, setLoading] = useState(false);
  const [isUnsignAlertOpen, setIsUnsignAlertOpen] = useState(false);
  const [isPatientView, setIsPatientView] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [syncState, setSyncState] = useState<"syncing" | "idle" | "done">("idle");

  const syncStatusFailed = getNoteSyncStatus(note);

  const isPatientAccessible = useMemo(() => {
    if (note.subject.resource) {
      return !!accessor.test(note.subject.resource);
    }
    return false;
  }, [note.id, accessor.openedPatients]);

  const { patient, encounter, groupNote } = data ?? {};

  useUpdateEffect(() => {
    setSyncState("idle");
  }, [note.id]);

  return (
    <Modal aria-label="Note Preview" isOpen={isOpen}>
      <div className={Classes.DIALOG_HEADER}>
        <h4 className={Classes.HEADING}>{!patient ? Text.RestrictedPatient(labels) : Text.NotePreview}</h4>
        {showPatientSignatures && (
          <PatientViewSwitch
            checked={isPatientView}
            label={Text.PatientView(labels)}
            onChange={handlePatientViewSwitchToggle}
          />
        )}
        {allowOpenChartSwitch && (
          <IconButton
            key="chart"
            minimal
            tooltip
            aria-label={`Open ${labels.Patient} Chart`}
            className={Classes.DIALOG_CLOSE_BUTTON}
            icon={<PatientChart />}
            onClick={() => openPatientChart(note.subject.id)}
          />
        )}
        {canEditSwitch() && (
          <Button
            key="edit"
            minimal
            className={Classes.DIALOG_CLOSE_BUTTON}
            disabled={!isPatientAccessible}
            icon={<Edit />}
            intent="primary"
            label={Text.Edit}
            onClick={() => handleEdit()}
          />
        )}
        <IconButton minimal aria-label="Close Dialog" className={Classes.DIALOG_CLOSE_BUTTON} icon="cross" onClick={onClose} />
      </div>
      <DialogBody className={Classes.DIALOG_BODY}>
        <NoteContent isPatientView={isPatientView} note={note} onLoad={setData} onLoadingChange={setLoading} />
      </DialogBody>
      {renderDialogFooter(encounter)}
      <NoteSummaryPrint isOpen={showPrint} isPatientView={isPatientView} note={note} onAfterPrint={handleAfterPrint} />
    </Modal>
  );

  function canEditSwitch(): boolean {
    if (!allowEditSwitch) {
      return false;
    }

    const hasAuthoredNote = note.participants.some(p => p.individual.id === user.person.id && (p.role === "PrimaryPerformer" || p.role === "Coordinator"));
    if (hasAuthoredNote) {
      return true;
    }

    // Admins can edit group notes like group admins
    return isAdmin && (isGroupNote(note) || !!note.partOf);
  }

  function openPatientChart(patientId: string) {
    navigate(generatePath(clinicalRoutes.patient, { patientId }));
    onClose?.();
  }

  function handleEdit() {
    if (note.partOf || note.status === "Preliminary") {
      onClose?.();
      noteDialog.openNote(note);
    }
  }

  async function handleUnsignNote(data: NoteContentData) {
    const signed = note.signatures.find(n => n.signer?.id === currentUser.id)?.signed;
    note.signatures = note.signatures.filter(s => s.signer?.id !== currentUser.id);
    note.status = "Preliminary";
    note.signDeadline = undefined;
    note.signLateness = undefined;

    const { encounter } = data;

    encounter.documentationStatus = "InProgress";

    // Avoid reverting old show-status of NoShow or Cancelled
    if (encounter.status === "Finished") {
      encounter.status = "Unknown";
    }

    note.encounter = {
      ...note.encounter,
      resource: encounter,
    };

    store.notes.upsert(note);
    store.encounters.upsert(encounter);

    // Re-open GT session if the author is unsigning
    if (note.derivedFrom) {
      const carePlanOutcome = await store.carePlanOutcomes.expand(note.derivedFrom);
      if (carePlanOutcome.partOf) {
        const carePlanSession = await store.carePlanSessions.expand(carePlanOutcome.partOf);
        if (carePlanSession.author.id === user.person.id && carePlanSession.status !== "Preliminary") {
          carePlanSession.status = "Preliminary";
          store.carePlanSessions.upsert(carePlanSession);
        }
      }
    }

    audit.log("note-updated", { entities: [note, note.subject] });

    if (signed) {
      tracking.track("Note - Unsign", {
        noteId: note.id,
        timeUnsigned: Math.round(DateTime.now().diff(Instant.toDateTime(signed)).as("minutes")),
      });
    }

    if (note.partOf && groupNote) {
      if (groupNote.status === "Final") {
        handleUnsignGroupNote();
      }
      noteDialog.openNote(groupNote);
    }
    setData({ ...data, encounter });
    onClose?.();
  }

  function handleUnsignGroupNote() {
    if (!groupNote?.encounter.resource) {
      return;
    }

    groupNote.signatures = groupNote.signatures.filter(s => s.signer?.id !== currentUser.id);
    groupNote.status = "Preliminary";
    groupNote.signDeadline = undefined;
    groupNote.signLateness = undefined;

    groupNote.encounter.resource.documentationStatus = "InProgress";
    groupNote.encounter.resource.status = "Unknown";

    groupNote.encounter = {
      ...groupNote.encounter,
      resource: groupNote.encounter.resource,
    };

    store.notes.upsert(groupNote);
    store.encounters.upsert(groupNote.encounter.resource!);
    audit.log("group-note-updated", { entities: [groupNote, groupNote.subject] });
  }

  function renderDialogFooter(encounter?: Encounter) {
    return (
      <>
        <Footer className={Classes.DIALOG_FOOTER}>
          <FooterActions>
            {showDeleteButton() && (
              <IconButton
                large
                minimal
                tooltip
                aria-label={Text.Delete}
                className="footer-option"
                disabled={!patient}
                icon="trash"
                iconSize={20}
                intent="primary"
                onClick={handleNoteDelete}
              />
            )}
            {practicePreference.allowNotePrintout && (
              <IconButton
                large
                minimal
                tooltip
                aria-label={Text.Print}
                disabled={!patient}
                icon={<Print />}
                iconSize={20}
                intent="primary"
                onClick={handlePrint}
              />
            )}
            {data && showUnsignButton(encounter) && allowUnsign() && (
              <>
                <Button
                  large
                  outlined
                  intent="primary"
                  label="Unsign"
                  onClick={() => setIsUnsignAlertOpen(true)}
                />
                <Alert
                  cancelButtonText="No"
                  confirmButtonText="Yes, unsign"
                  intent="primary"
                  isOpen={isUnsignAlertOpen}
                  onCancel={() => setIsUnsignAlertOpen(false)}
                  onConfirm={() => handleUnsignNote(data)}
                >
                  <p>{Text.UnsignAlertMessage}</p>
                </Alert>
              </>
            )}
            {canResync() && (
              <ButtonGroup large>
                <AnchorButton
                  large
                  outlined
                  disabled={!patient || syncState === "done"}
                  icon={syncState === "done" ? <Tick /> : <CloudUpload />}
                  intent="primary"
                  label={Text.Resync}
                  loading={syncState === "syncing"}
                  onClick={() => handleResync(false)}
                />
                {encounter?.documentationType === EncounterDocumentationType.Note && (
                  <Popover
                    content={(
                      <Menu>
                        <MenuItem
                          disabled={!patient || syncState === "done"}
                          text="Resync as Documentation Note"
                          onClick={() => handleResync(true)}
                        />
                      </Menu>
                    )}
                    disabled={syncState === "syncing"}
                    placement="top"
                  >
                    <IconButton
                      outlined
                      aria-label="More Options"
                      className="more"
                      icon="caret-down"
                      intent="primary"
                    />
                  </Popover>
                )}
              </ButtonGroup>
            )}
          </FooterActions>
          {showPagination && (
            <PaginationControls
              {...showPagination}
              canMoveNext={showPagination.canMoveNext && !loading}
              canMovePrevious={showPagination.canMovePrevious && !loading}
            />
          )}
        </Footer>
        {showDelete && (
          <DeleteNoteAlert
            isOpen={showDelete}
            note={note}
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        )}
      </>
    );
  }

  function handlePrint() {
    setShowPrint(true);
  }

  function handleAfterPrint() {
    setShowPrint(false);
  }

  function handleNoteDelete() {
    setShowDelete(true);
  }

  function handleCancelDelete() {
    setShowDelete(false);
  }

  async function handleConfirmDelete() {
    await deleteNote(note);
    setShowDelete(false);
  }

  function showDeleteButton() {
    const userOrSuperviseeIds = new Set<string>([session.person.id, ...session.supervisees.map(s => s.id)]);
    const isOwner = note.participants.some(p => userOrSuperviseeIds.has(p.individual.id) && p.role === ParticipantRole.PrimaryPerformer);
    const isGroupCoordinator = note.participants.some(p => userOrSuperviseeIds.has(p.individual.id) && p.role === ParticipantRole.Coordinator);
    const canUserDelete = isAdmin || isGroupCoordinator || isOwner;

    if (!canUserDelete) {
      return false;
    }

    if (!allowDeleteNote) {
      return false;
    }

    // If is author deleting their draft
    if (note.status === CompositionStatus.Preliminary) {
      return true;
    }

    if (adminNoteDelete && isAdmin && note.status === CompositionStatus.Final) {
      return true;
    }

    // If deleting failed sync item that cannot be resolved
    if (syncIssuesView && syncStatusFailed) {
      return true;
    }

    return false;
  }

  function showUnsignButton(encounter?: Encounter): encounter is PatientEncounter {
    if (!patient || !encounter) {
      return false;
    }

    return isPatientEncounter(encounter);
  }

  function allowUnsign() {
    if (!patient) {
      return false;
    }

    // Disallow if EHR signaled its too late
    if (note.forbidUnsign) {
      return false;
    }

    const userOrSuperviseeIds = new Set<string>([session.person.id, ...session.supervisees.map(s => s.id)]);
    const isAuthor = note.participants.some(p => userOrSuperviseeIds.has(p.individual.id));

    // Must be an admin, author, or supervisor
    if (!isAdmin && !isAuthor) {
      return false;
    }

    // Allow unsign if failed note
    if (syncStatusFailed === ResourceSyncStatus.Failed) {
      return true;
    }

    if (note.status === "Final" || note.status === "Amended") {
      // Allow the original author and signer to unsign within 72 threshold
      if (enableUnsignWithinThreshold && note.signatures.every(isSignatureWithinThreshold)) {
        return true;
      }

      const isSupport = user.impersonating || session.person.profile.roles.some(r => securityRoleIds.Support === r.id);
      if (isSupport) {
        return true;
      }
    }

    return false;
  }

  async function handleResync(forceDocumentationNote: boolean) {
    if (isPatientNote(note)) {
      setSyncState("syncing");
      try {
        if (forceDocumentationNote) {
          const encounter = await store.encounters.expand(note.encounter);
          encounter.appointment = undefined;
          encounter.documentationType = EncounterDocumentationType.DocumentationNote;
          await store.encounters.upsertAsync(encounter);
        }

        await summarizeNote(note);

        await store.notes.upsertAsync(note);
        await apollo.notes.syncById(note.partition, note.id);

        // Also trigger group note, if present
        if (note.partOf) {
          await apollo.notes.syncById(note.partOf.partition, note.partOf.id);
        }
      } catch (error) {
        handleError(error);
      } finally {
        setSyncState("done");
      }
    }
  }

  function canResync() {
    // Only support can resync
    if (!session.impersonating && !session.person.profile.roles.some(r => r.id === securityRoleIds.Support)) {
      return false;
    }

    return isPatientNote(note) && note.status === "Final" && note.forbidUnsign !== true;
  }

  function handlePatientViewSwitchToggle(event: React.FormEvent<HTMLInputElement>) {
    setIsPatientView(event.currentTarget.checked);
  }
}

export interface DialogPaginationProps {
  canMoveNext: boolean;
  canMovePrevious: boolean;
  count: JSX.Element | null;
  onNext: () => void;
  onPrev: () => void;
}

function PaginationControls(props: DialogPaginationProps) {
  const { canMovePrevious, canMoveNext, count, onPrev, onNext } = props;

  return (
    <div className="preview-controls">
      {count && (
        <div className="count-message">
          {count}
        </div>
      )}
      <IconButton
        minimal
        tooltip
        aria-label="Previous Note"
        className="prev"
        disabled={!canMovePrevious}
        icon={<CaretLeft />}
        intent="primary"
        onClick={handlePrev}
      />
      <IconButton
        minimal
        tooltip
        aria-label="Next Note"
        className="next"
        disabled={!canMoveNext}
        icon={<CaretRight />}
        intent="primary"
        onClick={handleNext}
      />
    </div>
  );

  function handlePrev(e: React.MouseEvent) {
    e.preventDefault();
    onPrev();
  }

  function handleNext(e: React.MouseEvent) {
    e.preventDefault();
    onNext();
  }
}

function isSignatureWithinThreshold(signature: Signature) {
  const signedDate = Instant.toDateTime(signature.signed);
  return signedDate && DateTime.now().diff(signedDate).as("hours") <= 72;
}
